import { takeEvery, call, put } from "redux-saga/effects";
import {
  SET_SMTP_DETAILS,
  GET_SMTP_DETAILS,
  SEND_TEST_EMAIL_RESPONSE,
} from "redux/actions/siteSettings.actions";
import { siteSettingsTypes } from "redux/actionTypes/siteSettings.types";
import SiteSettings from "hooks/siteSettingHooks/useSmtp";
import { getWhitelabel } from "apiCalls/whitelabelDb";
import {
  deleteSmtpDetail as delSmtp,
  sendTestEmail as send_mail,
} from "apiCalls/siteSettingsDb";
import { commonTypes } from "redux/actionTypes/common.types";
import {
  addBankDetailsApiCall,
  getBankDetailsApiCall,
} from "apiCalls/siteSettingsDb";
import { toast } from "react-toastify";

const { getSmtpDetailsBySiteId } = SiteSettings();

function* getSmtpDetails() {
  const smtpDetails = yield call(getSmtpDetailsBySiteId);
  yield put(SET_SMTP_DETAILS(smtpDetails));
}

function* deleteSmtpDetail(action) {
  yield call(delSmtp, { id: action.payload });
  yield put(GET_SMTP_DETAILS());
}

function* getSiteData() {
  const siteData = yield call(getWhitelabel);

  yield console.log("siteDataRedux", siteData);

  if (siteData.err) {
    yield put({
      type: commonTypes.SET_ALERT_MESSAGE,
      payload: { message: siteData.err.message },
    });
  } else if (!siteData.length) {
    // toast.error("Website data not found");
    yield put({
      type: commonTypes.SET_ALERT_MESSAGE,
      payload: { message: "No website registered yet!" },
    });
  } else {
    console.log("saving to redux");
    yield put({ type: siteSettingsTypes.SET_SITE_DATA, payload: siteData[0] });
    yield window.open("/");
  }

  console.log("site data redux ", siteData);
}

function* sendTestEmail(action) {
  yield console.log("send test email triggered", action.payload);
  yield put(SEND_TEST_EMAIL_RESPONSE(false));
  const apiRes = yield call(
    send_mail,
    action.payload.form,
    action.payload.smtp
  );
  yield console.log("api response", JSON.stringify(action.payload), apiRes);
  yield put(SEND_TEST_EMAIL_RESPONSE(apiRes));
}

function* getBankDetails(action) {
  const bankDetails = yield call(addBankDetailsApiCall, action.payload);
  if (!bankDetails) {
    yield put({
      type: commonTypes.SET_ALERT_MESSAGE,
      payload: { message: "Something Went Wrong" },
    });
  } else {
    toast.success("saved");
  }
}

function* setBankDetails(action) {
  const bankDetails = yield call(getBankDetailsApiCall, action.payload);
  if (bankDetails) {
    yield put({
      type: siteSettingsTypes.SET_BANK_DETAILS,
      payload: bankDetails,
    });
  }
}

export function* watchSiteSettings() {
  yield takeEvery(siteSettingsTypes.GET_SMTP_DETAILS, getSmtpDetails);
  yield takeEvery(siteSettingsTypes.DELETE_SMTP_DETAIL, deleteSmtpDetail);
  yield takeEvery(siteSettingsTypes.GET_SITE_DATA, getSiteData);
  yield takeEvery(siteSettingsTypes.SEND_TEST_EMAIL, sendTestEmail);
  yield takeEvery(siteSettingsTypes.GET_BANK_DETAILS, getBankDetails);
  yield takeEvery(siteSettingsTypes.BANK_DETAILS, setBankDetails);
}
