import { z } from "zod";

export const phoneNumberStringSchema = z
  .string()
  .refine((val) => val.length > 0, {
    message: "Phone Number should not be empty.",
  })
  .refine((val) => !isNaN(Number(val)), {
    message: "Phone Number should contain only digits.",
  });

export const bankDetailsSchema = z.object({
  bankName: z.string().min(1),
  bankCode: z.string().min(1),
  accountNumber: z.string().min(1),
  accountName: z.string().min(1),
  remark: z.string().min(1),
  bankAddress: z.string().min(1),
});
